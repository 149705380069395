<template>
    <div class="nav-wrapper">
        <div style="display: flex;align-items: center;">
            <img :src="env !== 'production' ? require('../assets/pc/logo_dev.png') : require('../assets/pc/logo.png')" class="logo-img" @click="handleGoToHome"/>
            <span>优品淘金</span>
            <span v-if="env !== 'production'">&nbsp;【测试版】</span>
        </div>
        <div class="nav-list">
            <div v-for="(item,index) in list" :key="index" :class="currentIndex === index ? 'nav-item nav-item-selected' : 'nav-item'" @click="handleChangeNav(index,item.path)">
                {{item.title}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "navbar",
        data(){
            return {
                env: process.env.VUE_APP_ENV,
                list:[
                    {
                        title: '首页',
                        path: '/home'
                    },
                    {
                        title: '玩法介绍',
                        path: '/introduce'
                    },
                    {
                        title: '关于我们',
                        path: '/about'
                    },
                    {
                        title: '联系我们',
                        path: '/relate'
                    }
                ],
                currentIndex: 0
            }
        },
        mounted(){
            console.log(this.env);
            const hash= window.location.hash;
            if(hash.indexOf('introduce')>-1){
                this.currentIndex = 1;
            } else if(hash.indexOf('about')>-1){
                this.currentIndex = 2;
            } else if(hash.indexOf('relate')>-1){
                this.currentIndex = 3;
            }else{
                this.currentIndex = 0;
            }
        },
        methods:{
            handleChangeNav(index,path){
                this.currentIndex = index;
                this.$router.push({
                    path: path
                })
            },
            handleGoToHome(){
                this.$router.push('./home');
            }
        }
    }
</script>

<style scoped lang="scss">
    @mixin flex{
        display: flex;
        align-items: center;
    }
    .nav-wrapper{
        z-index: 999;
        background: #FFFFFF;
        padding: 2px 320px;
        @include flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 400;
        color: #fa065a;

        .logo-img{
            width: 60px;
            height: 60px;
            cursor: pointer;
        }

        .nav-list{
            display: flex;

            .nav-item{
                height: 60px;
                @include flex;
                margin-right: 66px;
                border: none;
                cursor: pointer;
                &:last-child{
                    margin-right: 0;
                }
            }
            .nav-item-selected{
                border-bottom: 3px solid #F90751;
            }
        }
    }
</style>
