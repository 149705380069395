<template>
    <div class="record">
        <a :style="{'color':color}" @click="handleGoTo('https://beian.miit.gov.cn/#/Integrated/index')">Copyright  2019-2020广州市小鹰网络科技有限公司 粤ICP备2020135441号</a>
        <a @click="handleGoTo('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602008663')" :style="{'color':color}">
            粤公网安备44010602008663号
            <img src="../assets/pc/police.png"/>
        </a>
    </div>
</template>

<script>
    export default {
        name: "record",
        props:['color'],
        methods:{
            handleGoTo(url){
                window.open(url,'target','');
            }
        }
    }
</script>

<style scoped lang="scss">
    .record{
        width: 100%;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 400;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;

        a{
            cursor: pointer;
            &:last-child{
                display: flex;
                align-items: center;
                margin-left: 30px;
            }

            img{
                width: 18px;
                height: 18px;
                margin-left:2px;
            }
        }
    }
</style>
